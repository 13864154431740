import {useState} from 'react';

const Ascii = () => {
    const [actionCount, setActionCount] = useState(0);

    const handleAction = () => {
        setActionCount(prevCount => prevCount + 1);
    };

    if (actionCount === 42) {
        setActionCount(0);
        console.log('2', '8ea63da92593c');
    }

    return (
        <div className="ascii" onClick={handleAction}>
<pre>{`
                      @%                             @&                         
                        &@@                      /@@*                           
                           @@@                 &@/                              
                             .&&             &@                                 
                               ,&@         &@                                   
                                 &&      .@&                                    
                     @&&&         @&    .@#         @@&@                        
                        @@@%      @@@@@@@&&      @@@(                           
                         @@    @@@@ @@@@% &@&&   /&@                            
                        &@@    @@@@ @@@@@ @@@%    &@                            
                        @&    @@@@@@@@@@@@@@@@@   %@@                           
                       #@@   &@@@@@@@@@@@@@@@@@@   @&                           
                       @@/  @@@@@ %&&&@&&&# @@@@#  @&@                          
                      .@@@&@@@/@@@& &%&&& @@@@%@@.@@&@                          
                         @@@@(,@@@@@ ,#.,@@@@@ @@@&*                            
                       (@@@@% @&&@@@@@ @@@@@@&& @@@@@,                          
                %@@@@@@@@@@@ @&&@@@@@@@@@@@@&&&& @@@@@@@@@@@/                   
                @@@     @@@ &&%@@@@@@@%@@@@@@%%%& @@@    ,@@(                   
                @@&    @@@ #%%%@@@@@@%%%@@@@@@###( @@@    @@#                   
          @&#   @@&   @@@ ((#(@@@@@@     @@@@@@///( @@@   @@%   &&%             
                @@&  @@@ ////@@@@@@&&&&&&&@@@@@@*/*/ @@@  @&&                   
              @@@@% @@@ ,,**@@@@@@@@@@@@@@@@@@@@%,.*..@@& @&&&%                 
            ,@@    &@@ ..,.&@@@@@@@@@@@@@@@@@@@@@,.., (@@*    @@                
                  *@@*..../@@@@@@@@       @@@@@@@@..., &@@                      
          %@&     @@@    /@@@(@@@@@@@@@@@@@@@@@%@@@     @@@     @@.             
                 @@@    &@@@(#@@@@@@@@@@@@@@@@@/(@@@/    @@&                    
                @@@    @@@@,*@@@@@@&&&&&&@@@@@@%*,@@@@    &&@                   
               @@@   %@@@@,,,&@@@@@@     @@@@@@@..,@@@@,   @@&                  
              @@@   @@@@@@.. @@@@@@@@@@@@@@@@@@&. .@@@@@@   @@#                 
             #@@  @@@&@@@@  @@@@@@@@@@@@@@@@@@@@@  @@@&@@@@ .@&.                
            .&@.@@@@  @@@&,@@@ @@@@@@. %@@@@@&.@@@ @@@@  @@@@(@&                
            &@@@@@    @@@@@@&   &@@@@@,@@@@@.   @@@@@@@    @@@@@@               
           @@@@@      @@@@@       @@@@@@@@@      (@@@@@      @@@@@              
          @@@%        @@@@         @@@@@@(         &@@@        @@@%             
         %@,          @@.            @@@            #@@          #@             
                      @               &               @            .             
`}</pre>
        </div>
    );
};
export const gjlk324lgj4kgjkl23234kl5jgkl234jgkl324324l2345l234jl242l4 = ['58e', '787'];
export default Ascii;
