import {useState} from 'react';
import Ascii, {gjlk324lgj4kgjkl23234kl5jgkl234jgkl324324l2345l234jl242l4} from './ascii';

const ouig324ug = '9b4';
const jg13jl5fg123ouy5f3 = {
    jg3j5ghjl523f2vhjf31hj2f5hjl312: '4d',
    hjg5hj3g2hj5kf32khj5fk2h34jf5: 'a00',
};

const guig969kjGJKLgljGlj = {
    kjregwlitugfljksdgjkgdljfdgsuidrglhfjkdb: [
        {yuog5uy34g25oyu23ff3uy2f324: '4c'},
        '97',
    ],
};

const App = () => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragTimeoutId, setDragTimeoutId] = useState(null);

    const handleDragStart = (e) => {
        e.preventDefault();
        setIsDragging(true);

        if (dragTimeoutId) clearTimeout(dragTimeoutId);
        const timeoutId = setTimeout(() => {
            if (isDragging) {
                const kjlg534hj2g5hjl234543f5jh243 = gjlk324lgj4kgjkl23234kl5jgkl234jgkl324324l2345l234jl242l4.join('');
                const vvjh231vl5hj32v5jh1v5312 = jg13jl5fg123ouy5f3.jg3j5ghjl523f2vhjf31hj2f5hjl312 + jg13jl5fg123ouy5f3.hjg5hj3g2hj5kf32khj5fk2h34jf5;
                const kj32l4534g5kg234j5kg234 = guig969kjGJKLgljGlj.kjregwlitugfljksdgjkgdljfdgsuidrglhfjkdb.map(item => typeof item === 'object' ? item.yuog5uy34g25oyu23ff3uy2f324 : item).join('');

                console.log('8', ouig324ug + kjlg534hj2g5hjl234543f5jh243 + vvjh231vl5hj32v5jh1v5312 + kj32l4534g5kg234j5kg234);
            }
        }, 10000);

        setDragTimeoutId(timeoutId);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        if (dragTimeoutId) clearTimeout(dragTimeoutId);
        setDragTimeoutId(null);
    };

    return (
        <div className="container">
            <div className="hero">
                <h1>AEON.528</h1>

                <p>Where secrets of the past illuminate the future.</p>
                <span className="cta-button">Commence the Odyssey</span>
            </div>

            <div>
                <Ascii/>
            </div>

            <div className="section about">
                <h2>The Lore of Aeon 528</h2>
                <p>Within the shadow of history, an echo from the ancient order whispers. Are you ready to unravel the
                    threads of time and uncover truths long guarded?</p>

                <p>The AEON.528 pioneer NFT is not just a digital token; it encapsulates the essence of our project,
                    merging online and offline actions to create an immersive experience. As holders of this NFT,
                    participants will embark on a journey of discovery, where cleverness and ingenuity will be rewarded
                    with premium prizes and exclusive benefits. By successfully navigating through a series of tests and
                    challenges, both online and offline, individuals will unlock hidden secrets, unravel mysteries, and
                    pave the way for unprecedented rewards. This NFT serves as a tangible symbol of achievement, marking
                    the journey of those who dare to venture into the unknown realms of AEON.528, where every action
                    taken, whether online or offline, contributes to the collective narrative and shapes the future of
                    our enigmatic project.</p>
            </div>

            <div className="section how-it-works">
                <h2>The Path Forward</h2>
                <p>The journey is veiled in enigma. Each step a puzzle, every clue a deeper mystery. Your wit is the
                    key; your perseverance, the door.</p>

                <p style={{fontSize: 0, color: 'white', userSelect: 'none'}}>
                    Welcome to the first challenge of the AEON.528 journey. <br/>
                    <br/>
                    You stand at the threshold of discovery, poised to unravel the secrets hidden within the digital
                    realm. To begin your quest, you must first decipher the enigmatic code scattered throughout the
                    land.<br/>
                    The journey begins with three fragments, each holding a piece of the puzzle:<br/>
                    1. Seek the hidden knowledge stored within the memory of your device. Utilize the power of
                    your browser storage to retrieve the sacred fragment for '5'.<br/>
                    2. Venture into the depths of the console, where cryptic messages await. Listen closely as the
                    whispers of '2' and drag to find the '8'.<br/>
                    3. Assemble the fragments with care, for they hold the key to unlocking the mysteries that lie
                    ahead. Combine the wisdom of '5', '2', and '8' to reveal the encrypted code.<br/>
                    With the full encrypted code in hand, you will be one step closer to unlocking the secrets of
                    AEON.528. Let curiosity be your guide as you embark on this journey of discovery.
                </p>

                <p>In the shadowed realms of Lusitania, where the sun kisses the earth and whispers of antiquity dance
                    upon the winds, a cryptic call beckons seekers to uncover the forgotten secrets of ancient lands,
                    where legends of Luso-Roman lore intertwine with the echoes of Celtic chants and Moorish mysticism,
                    awaiting discovery amidst the ruins of Conímbriga and the sacred heights of Mons Medullius,
                    promising enlightenment and revelation to those who dare to embark upon this odyssey.</p>
            </div>

            <div className="faq section">
                <h2>Frequently Asked Questions</h2>
                <p>The wise seek not answers but understanding. What will you discover in the quest for questions?</p>
            </div>

            <div className="footer" draggable onDragStart={handleDragStart} onDragEnd={handleDragEnd}
                 onMouseLeave={handleDragEnd}>
                AEON.528
            </div>
        </div>
    );
}

export default App;
